import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../style/AboutUs.css";
import backgroundImage from "../img/bg2.jpg";
import designImage from "../img/DF picture.jpg";
import programPDF from "../style/Programs-and-Costs-.pdf"; // Make sure to have this PDF in your content folder
import { Helmet } from "react-helmet-async";
const AboutUs = () => {
  const [showPDF, setShowPDF] = useState(false);

  const togglePDF = () => {
    setShowPDF(!showPDF);
  };
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },[])
    // Animation variants
    const containerVariants = {
      hidden: { opacity: 0 },
      visible: {
        opacity: 1,
        transition: {
          staggerChildren: 0.3,
          delayChildren: 0.2
        }
      }
    };
  
    const itemVariants = {
      hidden: { y: 20, opacity: 0 },
      visible: {
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.5
        }
      }
    };

  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>About Us - The Daniel Foundation | Bringing Hope and Change</title>
        
        {/* Meta Description for SEO */}
        <meta 
          name="description" 
          content="The Daniel Foundation is dedicated to providing humanitarian assistance to vulnerable communities in Ethiopia. Join us in bringing hope to those affected by poverty, food insecurity, and displacement." 
        />
        
        {/* Keywords for SEO */}
        <meta 
          name="keywords" 
          content="Daniel Foundation, Ethiopia humanitarian aid, food insecurity, displaced people, poverty relief, emergency assistance, shelter kits, dignity kits, psychological support, women and children support" 
        />
        
        {/* Open Graph Meta Tags (For Social Media) */}
        <meta property="og:title" content="About Us - The Daniel Foundation | Helping Vulnerable Communities in Ethiopia" />
        <meta 
          property="og:description" 
          content="The Daniel Foundation works tirelessly to provide food, shelter, medical aid, and psychological support to those displaced and vulnerable in Northern Ethiopia." 
        />
        <meta property="og:image" content="https://www.danielfoundation.net/images/daniel-foundation-about-us.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.danielfoundation.net/about-us" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content="About Us - The Daniel Foundation | Changing Lives in Ethiopia" />
        <meta name="twitter:description" content="Join The Daniel Foundation in its mission to provide emergency aid, shelter, and support to vulnerable communities in Northern Ethiopia. Your contribution matters." />
        <meta name="twitter:image" content="https://www.danielfoundation.net/images/daniel-foundation-about-us.jpg" />
        <meta name="twitter:card" content="https://www.danielfoundation.net/images/daniel-foundation-about-us.jpg" />
      </Helmet>

      <div className="about-us-page">
      {/* Hero Section with Background Image */}
      <div 
        className="hero-section"
        style={{ 
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '10vh',
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="hero-content"
        >
          <h1>About Us</h1>
        </motion.div>
      </div>

      {/* Main Content Section */}
      <Container className="main-content">
        {/* Redesigned Design Image Section with Ovals */}
        <Row className="design-section-wrapper">
          <Col lg={6} className="oval-image-container">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              className="oval-parent"
            >
              <div className="oval-shape oval-1"></div>
              <div className="oval-shape oval-2"></div>
              <motion.div 
                className="design-image-container"
                whileHover={{ scale: 1.03 }}
                transition={{ duration: 0.3 }}
              >
                <img 
                  src={designImage} 
                  alt="Hope of Others" 
                  className="design-image"
                />
              </motion.div>
            </motion.div>
          </Col>
          
          <Col lg={6} className="design-content">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <h2 className="design-title">You're the Hope of Others</h2>
              <p className="design-text">
                Every small contribution makes a big difference in the lives of those we serve. 
                Join us in our mission to bring hope and change to vulnerable communities.
              </p>
            </motion.div>
          </Col>
          <Col md={6}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="stat-box"
            >
              <h4>22.6 Million</h4>
              <p>
                people in Ethiopia are food insecure due to drought, conflict and increase in food prices. (Source; February 2023 report, United Nations Office for Humanitarian Coordination (UNOCHA))
              </p>
            </motion.div>
          </Col>

          <Col md={6}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.4 }}
              className="stat-box"
            >
              <h4>Our Focus</h4>
              <p>
                Poverty and lack of opportunities aggravated by recent conflicts and food insecurities displaced thousands of people in Northern Ethiopia, where the project is located.
              </p>
            </motion.div>
          </Col>
        </Row>

        {/* Our Work Section */}
        <motion.section 
          className="our-work-section"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <Container fluid="lg">
            <Row className="mb-5">
              <Col md={12}>
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.8 }}
                >
                  <h2 className="section-title text-center mb-5">OUR WORK</h2>
                  
                  {/* Objectives Container */}
                  <motion.div 
                    className="objectives-container px-0"
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                  >
                    {/* Objective 1 */}
                    <motion.div 
                      className="objective-card"
                      whileHover={{ scale: 1.02 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="objective-header">
                        <motion.div 
                          className="objective-number"
                          initial={{ scale: 0 }}
                          whileInView={{ scale: 1 }}
                          viewport={{ once: true }}
                          transition={{ duration: 0.5, delay: 0.3 }}
                        >
                          1
                        </motion.div>
                        <h3>General Food Distribution</h3>
                      </div>
                      <div className="objective-content">
                        <p><strong>Objective:</strong> To provide General Food Distribution for 5,000 vulnerable persons (or 1,000 families). Supplementary nutritious food assistance for a target group of 2,000 poor and lactating mothers, medical cases, and malnourished children under 5 years of age.</p>
                        <h4>Activities:</h4>
                        <ul>
                          <li>Distribution of 5 kg protein (peas/beans), 5 litres fat (edible oil), and 50 kgs wheat flour per family for a total of 1,000 families (or 5,000 persons).</li>
                          <li>Edible Oil: 30,000 litres</li>
                          <li>Wheat flour: 300 metric tons</li>
                          <li>Provision of nutritious food supplements (assistance for a target group of 2,000 poor and lactating mothers, medical cases, and malnourished children under 5 years of age).</li>
                          <li>Vitamin A (5,000 infants).</li>
                        </ul>
                      </div>
                    </motion.div>

                    {/* Objective 2 */}
                    <motion.div 
                      className="objective-card"
                      whileHover={{ scale: 1.02 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="objective-header">
                        <motion.div 
                          className="objective-number"
                          initial={{ scale: 0 }}
                          whileInView={{ scale: 1 }}
                          viewport={{ once: true }}
                          transition={{ duration: 0.5, delay: 0.4 }}
                        >
                          2
                        </motion.div>
                        <h3>Dignity/Sanitary and Hygienic Kits</h3>
                      </div>
                      <div className="objective-content">
                        <p><strong>Objective:</strong> To provide dignity/sanitary and hygienic kits for 15,000 displaced women in shelters.</p>
                        <h4>Activities:</h4>
                        <ul>
                          <li>Provision of dignity/sanitary kits for a total of 1,000 women for 6 months.</li>
                          <li>Provision of sanitizers, mouth wash/nose masks and hand gloves for a total of 5,000 persons.</li>
                          <li>Provision of chlorine tablets for water purification for 1,000 households (or 5,000 persons).</li>
                          <li>Provision of sanitation tools and supplies for 1,000 households (1,000 water container jars shovels, 2,000 shovels). Local purchase pending availability of funding.</li>
                        </ul>
                      </div>
                    </motion.div>

                    {/* Objective 3 */}
                    <motion.div 
                      className="objective-card"
                      whileHover={{ scale: 1.02 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="objective-header">
                        <motion.div 
                          className="objective-number"
                          initial={{ scale: 0 }}
                          whileInView={{ scale: 1 }}
                          viewport={{ once: true }}
                          transition={{ duration: 0.5, delay: 0.5 }}
                        >
                          3
                        </motion.div>
                        <h3>Basic Shelter Sets</h3>
                      </div>
                      <div className="objective-content">
                        <p><strong>Objective:</strong> To provide basic shelter sets including 2,000 blankets, plastic sheeting (100 rolls), and family feeding & cooking sets for 1,000 families.</p>
                        <h4>Activities:</h4>
                        <ul>
                          <li>Provision of basic shelter sets including 10,000 blankets, plastic sheeting (100 rolls), and family feeding & cooking sets for 5,000 families.</li>
                        </ul>
                      </div>
                    </motion.div>

                    {/* Objective 4 */}
                    <motion.div 
                      className="objective-card"
                      whileHover={{ scale: 1.02 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="objective-header">
                        <motion.div 
                          className="objective-number p-2"
                          initial={{ scale: 0 }}
                          whileInView={{ scale: 1 }}
                          viewport={{ once: true }}
                          transition={{ duration: 0.5, delay: 0.6 }}
                        >
                          4
                        </motion.div>
                        <h3>Psychological/Counselling Support</h3>
                      </div>
                      <div className="objective-content">
                        <p><strong>Objective:</strong> To provide Psychological/Counselling support for targeted persons of 500, including abused women, girls, and children in order to create a protective environment towards social and emotional recovery.</p>
                        <h4>Activities:</h4>
                        <ul>
                          <li>Training of counselors (40) and supervisors (4) to work with 500 target persons.</li>
                          <li>Production and distribution of training and information materials on gender, children protection, COVID-19 & HIV/AIDS.</li>
                          <li>Provide financial assistance to 200 poor and abused persons/family to help set up/start own means of incomes. Coordinate with local administration, community, and religious leaders to help target groups reintegrate in the local communities.</li>
                        </ul>
                      </div>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </Col>
            </Row>
          </Container>
        </motion.section>

        {/* Program Schedule Section */}
        <section className="program-schedule-section mb-5">
          <Row>
            <Col md={12} className="text-center">
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              >
                <h2 className="section-title mb-4">OUR PROGRAM SCHEDULE</h2>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Button 
                    variant="danger" 
                    size="lg" 
                    className="pdf-button"
                    onClick={togglePDF}
                  >
                    {showPDF ? 'HIDE PROGRAM DETAILS' : 'CLICK TO VIEW OUR PROGRAMS AND COSTS'}
                  </Button>
                </motion.div>
                
                {showPDF && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.5 }}
                    className="pdf-container mt-4"
                  >
                    <iframe 
                      src={programPDF} 
                      width="100%" 
                      height="600px" 
                      title="Program Schedule PDF"
                      className="pdf-iframe"
                    />
                  </motion.div>
                )}
              </motion.div>
            </Col>
          </Row>
        </section>

        {/* Rest of your content */}
        <Row className="about-content">
          <Col md={12}>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
            >
                <Row>
                        <Col lg={12} className="mb-5 mb-lg-0">
                                <motion.div
                                  initial="hidden"
                                  whileInView="visible"
                                  viewport={{ once: true, margin: "-100px" }}
                                  variants={containerVariants}
                                >
                                  <motion.h2 
                                    className="display-5 fw-bold mb-4"
                                    style={{ color: '#dc3545' }}
                                    variants={itemVariants}
                                  >
                                    Our Mission
                                  </motion.h2>
                                  
                                  <motion.div 
                                    className="mb-4"
                                    style={{ height: '4px', backgroundColor: 'black', width: '100px' }}
                                    variants={itemVariants}
                                  />
                                  
                                  <motion.p 
                                    className="mb-4 fs-5"
                                    variants={itemVariants}
                                  >
                  The Daniel Foundation for Humanitarian Assistance in Raya is a Registered Foundation in Canada. Its mission is to undertake short term emergency and long-term humanitarian and development activities in Raya in order to restore livelihoods, dignity, and help address poverty of vulnerable poor communities. 
                                  </motion.p>
                  
                                  <motion.ul 
                                    className="mb-4 fs-5"
                                    style={{ listStyleType: 'none', paddingLeft: '0' }}
                                    variants={itemVariants}
                                  >
                                    <li className="mb-3 d-flex">
                                      <span className="me-2" style={{ color: '#dc3545', fontWeight: 'bold' }}>•</span>
                                      Provide immediate emergency assistance to save lives and alleviate suffering
                                    </li>
                                    <li className="mb-3 d-flex">
                                      <span className="me-2" style={{ color: '#dc3545', fontWeight: 'bold' }}>•</span>
                                      Implement long-term development programs to rebuild communities and restore dignity
                                    </li>
                                  </motion.ul>
                  
                                  <motion.div 
                                    className="p-4 mb-4"
                                    style={{ 
                                      backgroundColor: '#f8f9fa', 
                                      borderLeft: '4px solid #dc3545',
                                      borderRight: '1px solid #ddd',
                                      borderTop: '1px solid #ddd',
                                      borderBottom: '1px solid #ddd'
                                    }}
                                    variants={itemVariants}
                                  >
                                    <p className="mb-0 fw-bold">
                                    The focus and target communities of the Raya Project are poor girls, poor boys, orphan children and most vulnerable women who do not have any income means to sustain themselves.                  </p>
                                  </motion.div>
                                </motion.div>
                              </Col>
                </Row>
              
            </motion.div>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  
  );
};

export default AboutUs;