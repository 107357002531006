import { FileText, FileImage, FileCode, File,FileSpreadsheet } from "lucide-react";
import "../style/documenticon.css"
const DocumentIcon = ({ name, type,fileUrl }) => {
    
  const getIcon = () => {
    switch (type) {
      case "pdf":
        return <FileText className="text-danger" size={90} />;
        case "docx":
        return <FileText className="text-info" size={90} />;
      case "image":
        return <FileImage className="text-primary" size={90} />;
      case "xlsx":
        return <FileSpreadsheet className="text-success" size={90} />;
      default:
        return <File className="text-secondary" size={90} />;
    }
  };

  return (
    <div className="text-center mx-2 document-icon rounded">
        {type==="pdf" ?( <a href={fileUrl} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
      <div className="border rounded p-2 d-inline-block">
        {getIcon()}
      </div>
      <div className=" text-truncate doc-text mt-2 text-center" style={{ maxWidth: "" }}>
        <h6 className="doc-text"> {name}</h6>
      </div>
      </a>):(
         <a href={fileUrl} download target="_blank" rel="noopener noreferrer" className="text-decoration-none">
         <div className="border rounded p-2 d-inline-block">
           {getIcon()}
         </div>
         <div className=" text-truncate doc-text mt-2 text-center" style={{ maxWidth: "" }}>
           <h6 className="doc-text"> {name}</h6>
         </div>
         </a>
      )}
       
    </div>
  );
};


export default DocumentIcon