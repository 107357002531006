import React,{useEffect} from 'react';
import { motion } from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/Home.css';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import heroImage from '../img/Sweet little girl.jpg';
import Mission from './Mission';

const Home = () => {
  useEffect(()=>{
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },[])
  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>The Daniel Foundation - Supporting Vulnerable Children in Raya</title>
        
        {/* Updated Meta Description for SEO */}
        <meta 
          name="description" 
          content="The Daniel Foundation is dedicated to providing emergency aid, shelter, and medical support to vulnerable children in Raya. Join us in raising $100K to rebuild lives and restore hope." 
        />
        
        {/* Keywords for SEO */}
        <meta 
          name="keywords" 
          content="Daniel Foundation, humanitarian aid, emergency relief, child welfare, Ethiopia support, Raya crisis, nonprofit assistance" 
        />
        
        {/* Open Graph Meta Tags (For Social Media) */}
        <meta property="og:title" content="The Daniel Foundation - Rebuilding Lives in Raya" />
        <meta 
          property="og:description" 
          content="Help us provide emergency relief, medical aid, and sustainable support to children in Raya. Your contribution can make a difference." 
        />
        <meta property="og:image" content="https://www.danielfoundation.org/images/cover.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.danielfoundation.net" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content="The Daniel Foundation - Supporting Vulnerable Children in Raya" />
        <meta name="twitter:description" content="Providing emergency aid, shelter, and medical assistance to vulnerable children in Raya. Join our mission to restore hope." />
        <meta name="twitter:image" content="https://www.danielfoundation.org/images/cover.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
    <div className="main-container  bg-danger">
      {/* Home Section */}
      <div className="home-section ">
        <div className="hero-container">
          <motion.div 
            className="hero-image-container"
            initial={{ scale: 1.1 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1.5, ease: "easeOut" }}
          >
            <img 
              src={heroImage} 
              alt="Raya's Children" 
              className="hero-image"
              onLoad={(e) => {
                e.target.style.opacity = 1;
              }}
              style={{ opacity: 0, transition: 'opacity 0.5s ease-in' }}
            />
            <div className="hero-overlay"></div>
            <div className="hero-gradient-overlay"></div>
          </motion.div>

          <div className="container hero-content">
            <div className="row">
              <div className="col-lg-8">
                <motion.div
                  initial={{ opacity: 0, y: 40 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0.3 }}
                >
                  <h5 className="hero-subtitle text-white mb-3">
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.5 }}
                    >
                      The Daniel Foundation
                    </motion.span>
                  </h5>
                  <motion.h1 
                    className="hero-title text-white mb-4"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.6, duration: 0.8 }}
                  >
                    For Humanitarian Assistance in Raya
                  </motion.h1>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.8, duration: 0.8 }}
                >
                  <motion.h2 
                    className="hero-headline text-white mb-4"
                    whileInView={{ opacity: 1, y: 0 }}
                    initial={{ opacity: 0, y: 20 }}
                    transition={{ delay: 0.9 }}
                  >
                    Help Restore Hope to Raya's Children
                  </motion.h2>
                  <motion.p 
                    className="hero-text text-white mb-5"
                    whileInView={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ delay: 1.0 }}
                  >
                    The Daniel Foundation for Humanitarian Assistance is raising $100K to provide temporary shelters, 
                    medicine, and food preparation sites for vulnerable children in Raya. Your generous support 
                    can make a life-changing difference.
                  </motion.p>

                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1.2 }}
                  >
                    <button className="hero-button btn btn-lg px-5 py-3">
                    <Link to="/donate-now"className="hero-button btn btn-lg px-5 py-3">
  <span className="button-text">DONATE NOW</span>
  <span className="button-subtext d-block">CLICK TO DONATE TODAY!</span>
</Link>
                      <div className="button-hover-effect"></div>
                    </button>
                  </motion.div>
                </motion.div>
              </div>

              <motion.div 
                className="col-lg-4 stats-container"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1.0, duration: 0.8 }}
              >
                <div className="stats-box p-4">
                  <div className="stat-item mb-4">
                    <motion.div
                      animate={{ 
                        scale: [1, 1.1, 1],
                      }}
                      transition={{ 
                        repeat: Infinity, 
                        repeatType: "reverse",
                        duration: 3
                      }}
                    >
                      <div className="stat-number text-white">$100K</div>
                      <div className="progress-bar-container">
                        <motion.div 
                          className="progress-bar-fill"
                          initial={{ width: 0 }}
                          animate={{ width: '65%' }}
                          transition={{ delay: 1.3, duration: 1.5, ease: "easeOut" }}
                        ></motion.div>
                      </div>
                    </motion.div>
                    <div className="stat-label text-white">Fundraising Goal</div>
                  </div>
                  
                  <div className="stat-item mb-4">
                    <motion.div
                      animate={{ 
                        scale: [1, 1.05, 1],
                      }}
                      transition={{ 
                        repeat: Infinity, 
                        repeatType: "reverse",
                        duration: 4,
                        delay: 0.5
                      }}
                    >
                      <div className="stat-number text-white">214</div>
                    </motion.div>
                    <div className="stat-label text-white">Children Helped</div>
                  </div>
                  
                  <div className="stat-item">
                    <motion.div
                      animate={{ 
                        scale: [1, 1.05, 1],
                      }}
                      transition={{ 
                        repeat: Infinity, 
                        repeatType: "reverse",
                        duration: 4,
                        delay: 1
                      }}
                    >
                      <div className="stat-number text-white">66</div>
                    </motion.div>
                    <div className="stat-label text-white">Days Left</div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      {/* Mission Section (Below Home) */}
      <div className="mission-section">
        <Mission />
      </div>
    </div>
    </>
  );
};

export default Home;
