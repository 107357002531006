import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Use Routes instead of Route for React Router v6
import Navigation from './component/Nav.jsx';
import Footer from './component/Footer.jsx';
import Home from './component/Home.jsx';
import AboutUs from './component/AboutUs.jsx';  // Assuming you have this component
import WhoWeAre from './component/WhoWeAre.jsx';  // Assuming you have this component
import HowWeDoIt from './component/HowWeDoIt.jsx';  // Assuming you have this component
import ContactUs from './component/ContactUs.jsx';  // Assuming you have this component
import DonateNow from './component/DonateNow.jsx';  // Assuming you have this component
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/Nav.css';

function App() {
  return (
    <Router>
      <Navigation className="mb-5" />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/who-we-are" element={<WhoWeAre />} />
        <Route path="/how-we-do-it" element={<HowWeDoIt />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/donate-now" element={<DonateNow />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
