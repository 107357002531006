import React,{useState} from 'react'
import { Button } from 'react-bootstrap';

function Readmore({text, maxLength = 250}) {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleReadMore = () => setIsExpanded(!isExpanded);
  
    return (
      <div>
        {isExpanded ? (
          text.split("\n").map((paragraph, index) => (
            <p key={index} className="mb-2">{paragraph}</p>
          ))
        ) : (
          <p>
            {text.slice(0, maxLength)}...
          </p>
        )}
        {text.length > maxLength && (
          <Button  onClick={toggleReadMore} variant="danger" size="lg" className="px-4" >
            {isExpanded ? "Read Less" : "Read More"}
          </Button>
        )}
      </div>
    );
  };


export default Readmore