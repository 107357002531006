import React, { useState,useEffect } from 'react';
import DocumentIcon from './DocumentIcon';
import { motion } from 'framer-motion';
import bylawdocs from "../style/By-Laws Daniel Foundation.docx"
import dfforcast from "../style/DF 3 yr budget Forecast.xlsx"
import { Helmet } from 'react-helmet-async';
import incorporation from "../style/Incorporation 1.pdf"
import supportletter from "../style/The Daniel Foundation - Letter of Support.pdf"
import { FaHandHoldingHeart, FaBalanceScale, FaShieldAlt, FaChartLine, FaFileAlt, FaDollarSign } from 'react-icons/fa';
import { Carousel } from 'react-bootstrap';
import corePrinciplesImage from '../img/how1.jpg'; // Replace with your actual image path
import '../style/HowWeDoIt.css';
import { Link } from 'react-router-dom';
const HowWeDoIt = () => {
  const [activeTab, setActiveTab] = useState('core-principles');
  const documents = [
    { name: "Our by Laws.docx", type: "docx" ,fileUrl:bylawdocs },
    { name: "Daniel Foundation 3 year forecast.xlsx", type: "xlsx" ,fileUrl:dfforcast },
    { name: "Incorporation.pdf", type: "pdf",fileUrl:incorporation },
    { name: "Support letter.pdf", type: "pdf",fileUrl:supportletter },
  ];

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        when: "beforeChildren"
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5
      }
    }
  };

  // Core Principles Data
  const corePrinciples = [
    {
      icon: <FaHandHoldingHeart size={40} className="text-danger" />,
      title: "Humanity & Voluntary Service",
      description: "Protection of life and health and ensuring respect for human beings."
    },
    {
      icon: <FaBalanceScale size={40} className="text-danger" />,
      title: "Neutrality",
      description: "The Proposal takes a neutral role in all its dealings with all parties, not siding with any party in implementation."
    },
    {
      icon: <FaShieldAlt size={40} className="text-danger" />,
      title: "Impartiality",
      description: "No discrimination in humanitarian activities, as needs of the most vulnerable determine the intervention."
    },
    {
      icon: <FaShieldAlt size={40} className="text-danger" />,
      title: "Safety & Accessibility",
      description: "Security measures and code of conduct guide field activities in designated areas."
    }
  ];

  // Reporting Data
  const reportingItems = [
    {
      icon: <FaFileAlt size={40} className="text-danger" />,
      title: "Monthly Situation Report",
      description: "Highlighting key activities, incomes and expenditures for the period."
    },
    {
      icon: <FaChartLine size={40} className="text-danger" />,
      title: "6-Month Biannual Report",
      description: "Measuring performance/progress against targets, addressing challenges."
    },
    {
      icon: <FaFileAlt size={40} className="text-danger" />,
      title: "Yearly Analytic Report",
      description: "Provides analysis of all Proposal activities and financial performance."
    },
    {
      icon: <FaDollarSign size={40} className="text-danger" />,
      title: "Detailed Financial Report",
      description: "Comprehensive financial reporting with accountability measures."
    }
  ];
  useEffect(()=>{
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },[])

  return (
    <>
    <Helmet>
        {/* Page Title */}
        <title>How We Do It - The Daniel Foundation | Humanitarian Assistance in Raya</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="Discover how The Daniel Foundation provides both short-term emergency and long-term humanitarian assistance to vulnerable communities in Raya, adhering to universal humanitarian principles."
        />

        {/* Keywords for SEO */}
        <meta
          name="keywords"
          content="humanitarian assistance, emergency aid, long-term aid, vulnerable communities, Raya, universal humanitarian principles, protection, neutrality, impartiality, safety, accessibility"
        />

        {/* Open Graph Meta Tags (For Social Media) */}
        <meta property="og:title" content="How We Do It - The Daniel Foundation | Humanitarian Assistance in Raya" />
        <meta
          property="og:description"
          content="Explore how The Daniel Foundation addresses both emergency and long-term humanitarian needs in Raya through its core principles of humanity, neutrality, and impartiality."
        />
        <meta property="og:image" content="https://www.danielfoundation.net/images/how-we-do-it-image.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.danielfoundation.net/how-we-do-it" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content="How We Do It - The Daniel Foundation | Humanitarian Assistance in Raya" />
        <meta
          name="twitter:description"
          content="Learn about The Daniel Foundation's approach to humanitarian aid in Raya, focused on emergency relief and long-term support for vulnerable communities."
        />
        <meta name="twitter:image" content="https://www.danielfoundation.net/images//how-we-do-it-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

    <section className="py-5 bg-white" id="how-we-do-it">
      <div className="container">
        {/* Section Header */}
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-5"
        >
          <h2 className="display-4 fw-bold text-black mb-3">How We Do It</h2>
          <p className="lead text-muted">
            The Daniel Foundation for Humanitarian Assistance in Raya is dedicated to addressing both short-term emergency and long-term humanitarian needs of vulnerable communities.
          </p>
        </motion.div>

        {/* Navigation Tabs */}
        <div className="d-flex justify-content-center mb-5">
          <div className="btn-group" role="group">
            <button
              className={`btn btn-lg ${activeTab === 'core-principles' ? 'btn-danger' : 'btn-outline-danger'}`}
              onClick={() => setActiveTab('core-principles')}
            >
              Core Principles
            </button>
            <button
              className={`btn btn-lg ${activeTab === 'reporting' ? 'btn-danger' : 'btn-outline-danger'}`}
              onClick={() => setActiveTab('reporting')}
            >
              Reporting & Accountability
            </button>
          </div>
        </div>

        {/* Core Principles Section */}
        {activeTab === 'core-principles' && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={containerVariants}
            className="row g-4"
          >
            <div className="col-lg-6">
              <motion.div variants={itemVariants}>
                <img 
                  src={corePrinciplesImage} 
                  alt="Core Principles" 
                  className="img-fluid rounded shadow-lg"
                />
              </motion.div>
            </div>
            <div className="col-lg-6">
              <motion.div variants={itemVariants}>
                <h3 className="fw-bold text-black mb-4">Core Principles Guiding the Program</h3>
                <p className="mb-4">
                  The Proposal adheres to the following Universal Humanitarian Principles while executing its objectives (HIF, 2022):
                </p>
                
                <div className="row g-4">
                  {corePrinciples.map((principle, index) => (
                    <motion.div 
                      key={index}
                      variants={itemVariants}
                      className="col-md-6"
                      whileHover={{ scale: 1.03 }}
                    >
                      <div className="card h-100 border-0 shadow-sm">
                        <div className="card-body text-center p-4">
                          <div className="mb-3">{principle.icon}</div>
                          <h5 className="card-title fw-bold text-black">{principle.title}</h5>
                          <p className="card-text">{principle.description}</p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>

            </div>
            <div className="container mt-5">
               <motion.h2 
                  className="display-5 fw-bold mb-4 text-center"
                   style={{ color: '#dc3545' }}
                   variants={itemVariants} >
                    Our Supporting Documents
                     </motion.h2>
              <motion.div variants={itemVariants} className='row mt-2'>

              
              {documents.map((doc, index) => (
                <div className="col-12 col-md-3 mx-auto">
            <DocumentIcon key={index} name={doc.name} type={doc.type} fileUrl={doc.fileUrl} />
            </div>
             ))}
            
           
              

              </motion.div>
            </div>
       
          </motion.div>
          
        )}

        {/* Reporting Section */}
        {activeTab === 'reporting' && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <div className="row mb-5">
              <div className="col-lg-8 mx-auto text-center">
                <motion.div variants={itemVariants}>
                  <h3 className="fw-bold text-black mb-4">Reporting, Discipline & Accountability</h3>
                  <p className="lead">
                    The Proposal ensures strict accountability and reporting of funds and all resources through comprehensive compliance instruments.
                  </p>
                </motion.div>
              </div>
            </div>

            <div className="row g-4 mb-5">
              {reportingItems.map((item, index) => (
                <motion.div 
                  key={index}
                  variants={itemVariants}
                  className="col-md-6 col-lg-3"
                  whileHover={{ scale: 1.03 }}
                >
                  <div className="card h-100 border-0 shadow-sm">
                    <div className="card-body text-center p-4">
                      <div className="mb-3">{item.icon}</div>
                      <h5 className="card-title fw-bold text-black">{item.title}</h5>
                      <p className="card-text">{item.description}</p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>

            <motion.div 
              variants={itemVariants}
              className="bg-light p-4 rounded shadow-sm mb-5"
            >
              <h4 className="fw-bold text-black mb-3">Detailed Reporting Process</h4>
              <ul className="list-unstyled">
                <li className="mb-2 d-flex">
                  <span className="text-danger me-2">•</span>
                  <span>Field level monitoring of the Proposal is held once a week to closely follow-up activities.</span>
                </li>
                <li className="mb-2 d-flex">
                  <span className="text-danger me-2">•</span>
                  <span>Yearly Analytic Report provides analysis of the Proposal activities, performance vs. targets, and explanations for any deviations.</span>
                </li>
                <li className="mb-2 d-flex">
                  <span className="text-danger me-2">•</span>
                  <span>Detailed Financial Report prepared indicating the Proposal financial performance against target objectives.</span>
                </li>
                <li className="d-flex">
                  <span className="text-danger me-2">•</span>
                  <span>Plan for 1st and 2nd years report of the program with narrative analysis of all aspects.</span>
                </li>
              </ul>
            </motion.div>

            {/* Slideshow */}
            <motion.div
              variants={itemVariants}
              className="mb-5"
            >
              <Carousel indicators={false} controls={false} interval={3000} pause={false}>
                <Carousel.Item>
                  <div className="bg-danger text-white p-5 rounded text-center">
                    <h3 className="fw-bold">Monthly Reports</h3>
                    <p className="mb-0">Highlighting key activities, incomes and expenditures for each period.</p>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="bg-dark text-white p-5 rounded text-center">
                    <h3 className="fw-bold">Biannual Reports</h3>
                    <p className="mb-0">Measuring performance/progress against targets with financial reporting.</p>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="bg-danger text-white p-5 rounded text-center">
                    <h3 className="fw-bold">Yearly Analysis</h3>
                    <p className="mb-0">Comprehensive review of all Proposal activities and outcomes.</p>
                  </div>
                </Carousel.Item>
              </Carousel>
            </motion.div>
          </motion.div>
        )}

        {/* CTA Section */}
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="bg-danger text-white p-5 rounded-3 shadow-lg mt-5"
        >
          <div className="row align-items-center">
            <div className="col-lg-8 mb-4 mb-lg-0">
              <h3 className="fw-bold mb-3">Join Us in Making a Difference</h3>
              <p className="mb-0">
                Your donation can help us provide emergency assistance, rebuild schools, and support vulnerable communities in Raya and South Wollo.
              </p>
            </div>
            <div className="col-lg-4 text-lg-end">
            <Link to="/donate-now"className="">
              <button className="btn btn-light btn-lg px-4 py-3 fw-bold text-danger">
                Donate Now
              </button>
              </Link>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
    </>
    
  );
};

export default HowWeDoIt;