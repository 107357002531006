export const founder_bio=` Welcome to the profile of Meron Lemma, founder of a humanitarian organization.
    
    Meron brings a wealth of experience in humanitarian-related endeavors, with a background spanning over a decade. She has dedicated ten years to serving as a midwife, providing essential care and support during childbirth. Additionally, Meron has spent four years actively involved in nurturing and assisting orphaned children, ensuring their well-being and development.
    
    Moreover, Meron has contributed two years to a humanitarian organization focused on empowering women in need in Ethiopia, affiliated with Geneva’s esteemed humanitarian institution. Furthermore, Meron has collaborated with multiple European organizations for four years, specializing in the identification and support of adopted children, fostering their sense of identity and belonging.
    
    In the medical realm, Meron has served for three years as an assistant nurse in an MS (multiple sclerosis) unit, providing vital assistance and care to patients battling this condition. Beyond healthcare, Meron has ventured into facility management, gaining one year of experience as a building manager in Canada, ensuring the efficient operation and maintenance of premises.
    
    Meron’s commitment to community welfare is evident through fifteen years of volunteering, actively engaging in various community projects and initiatives aimed at making a positive impact. In the realm of marketing, she has honed her skills through a year and a half as a marketing manager, effectively promoting products and services to target audiences.
    
    Additionally, Meron has utilized her linguistic abilities and counseling expertise during two and a half years as a language interpreter and guidance counselor for refugees, working with Immigration Canada facilitating communication and providing support to individuals navigating challenging circumstances, including Health Care support. Lastly, she has dedicated over a decade to supporting Canadian seniors, offering care, companionship, and assistance to enhance their quality of life based on her medical experience.
    
    Overall, Meron’s diverse experiences and unwavering dedication make her a valuable asset capable of making significant contributions to humanitarian endeavors and community welfare initiatives.
  `;