import React,{useEffect} from 'react';
import { motion } from 'framer-motion';
import { Helmet  } from 'react-helmet-async';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { FaHeart, FaHandHoldingHeart, FaUsers, FaChild } from 'react-icons/fa';
import donationImage from '../img/Ethiopia 🇪🇹 (1).jpg'; // Replace with your actual image path
import '../style/DonateNow.css'
const DonateNow = () => {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        when: "beforeChildren"
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5
      }
    }
  };

  const pulseAnimation = {
    scale: [1, 1.05, 1],
    transition: {
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut"
    }
  };
  useEffect(()=>{
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },[])

  return (
    <>
    <Helmet>
        {/* Page Title */}
        <title>Donation- The Daniel Foundation | Help Transform Lives in Raya</title>

        {/* Meta Description for SEO */}
        <meta
          name="description"
          content="Your donation helps transform lives in Raya by providing emergency aid and long-term support to orphaned children, vulnerable women, and communities in need."
        />

        {/* Keywords for SEO */}
        <meta
          name="keywords"
          content="donation, transform lives, community in need, orphaned children, vulnerable women, emergency aid, Raya, The Daniel Foundation, make a difference"
        />

        {/* Open Graph Meta Tags (For Social Media) */}
        <meta property="og:title" content="Community in Need - The Daniel Foundation | Help Transform Lives in Raya" />
        <meta
          property="og:description"
          content="Your generous donation helps provide aid and support to vulnerable communities in Raya, empowering orphaned children and women. Donate now and make a real difference!"
        />
        <meta property="og:image" content="https://www.danielfoundation.net/images/donate-now-image.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.danielfoundation.org/donate-now" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content="Community in Need - The Daniel Foundation | Help Transform Lives in Raya" />
        <meta
          name="twitter:description"
          content="Join The Daniel Foundation in making a difference for orphaned children, vulnerable women, and communities in Raya. Every donation helps transform lives!"
        />
        <meta name="twitter:image" content="https://www.danielfoundation.net/images/donate-now-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

     <section className="donate-section py-5" style={{ backgroundColor: '#f8f9fa' }}>
      <Container>
        {/* Hero Image with Overlay Text */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="mb-5 position-relative"
        >
          <img 
            src={donationImage} 
            alt="Community in need" 
            className="img-fluid rounded shadow-lg w-100"
            style={{ maxHeight: '400px', objectFit: 'cover' }}
          />
          <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center">
            <motion.h1 
              className="text-white display-4 fw-bold text-center px-3"
              style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.8 }}
            >
              Help Transform Lives in Raya
            </motion.h1>
          </div>
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <Row className="justify-content-center g-4">
            {/* Donation Impact Info */}
            <Col lg={6}>
              <motion.div variants={itemVariants}>
                <Card className="border-0 shadow-sm h-100" style={{ borderRadius: '20px' }}>
                  <Card.Body className="p-4">
                    <motion.div 
                      className="bg-danger rounded-circle mx-auto mb-4 d-flex align-items-center justify-content-center"
                      style={{ width: '80px', height: '80px' }}
                      animate={pulseAnimation}
                    >
                      <FaHeart className="text-white" size={30} />
                    </motion.div>
                    
                    <h2 className="text-center mb-4 fw-bold" style={{ color: '#dc3545' }}>Your Donation Makes a Difference</h2>
                    
                    <p className="lead text-center mb-4">
                      Your generous donation helps us provide vital emergency aid and long-term support to vulnerable communities in Raya. By contributing, you restore hope and dignity to poor girls, boys, orphaned children, and needy women. Every dollar makes a difference.
                    </p>
                    
                    <p className="text-center fw-bold mb-4">
                      Join us in making a tangible impact—donate today and transform lives!
                    </p>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>

            {/* Donation Form */}
            <Col lg={6}>
              <motion.div variants={itemVariants}>
                <Card className="border-0 shadow h-100" style={{ borderRadius: '20px', border: '2px solid #dc3545' }}>
                  <Card.Body className="p-4">
                    <motion.div 
                      className="bg-dark rounded-circle mx-auto mb-4 d-flex align-items-center justify-content-center"
                      style={{ width: '80px', height: '80px' }}
                      whileHover={{ scale: 1.1 }}
                    >
                      <FaHandHoldingHeart className="text-white" size={30} />
                    </motion.div>
                    
                    <h2 className="text-center mb-4 fw-bold" style={{ color: '#dc3545' }}>Donate Now</h2>
                    
                    <motion.div 
                      className="bg-light p-4 mb-4 rounded"
                      style={{ borderLeft: '4px solid #dc3545' }}
                      whileHover={{ x: 5 }}
                    >
                      <h5 className="fw-bold mb-3">How your donation helps:</h5>
                      <ul className="list-unstyled">
                        <li className="mb-2 d-flex align-items-center">
                          <FaChild className="text-danger me-2" /> 
                          <span>Support orphaned children</span>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <FaUsers className="text-danger me-2" /> 
                          <span>Empower vulnerable women</span>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <FaHeart className="text-danger me-2" /> 
                          <span>Provide emergency aid</span>
                        </li>
                      </ul>
                    </motion.div>
                    
                    <motion.div
                      className="text-center"
                      whileHover={{ scale: 1.02 }}
                    >
                      <Button 
                        variant="danger" 
                        size="lg" 
                        className="fw-bold px-5 py-3 rounded-pill"
                        onClick={() => window.open('https://buy.stripe.com/eVa8y76C03nRc3CfYY', '_blank')}
                      >
                        Donate via Secure Payment
                      </Button>
                    </motion.div>
                    
                    <motion.div 
                      className="mt-4 text-center"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.5 }}
                    >
                      <small className="text-muted">100% secure donation process</small>
                    </motion.div>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
          </Row>
        </motion.div>

        {/* Impact Circles */}
        <motion.div 
          className="mt-5 pt-4"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <h3 className="text-center mb-5 fw-bold" style={{ color: '#dc3545' }}>Your Impact in Numbers</h3>
          
          <Row className="g-4 justify-content-center">
            {[
              { number: "500+", text: "Children Helped", icon: <FaChild /> },
              { number: "1,200+", text: "Lives Touched", icon: <FaUsers /> },
              { number: "50+", text: "Communities Served", icon: <FaHeart /> }
            ].map((item, index) => (
              <Col md={4} key={index}>
                <motion.div
                  className="text-center"
                  whileHover={{ scale: 1.05 }}
                >
                  <div 
                    className="mx-auto mb-3 d-flex align-items-center justify-content-center bg-danger text-white rounded-circle shadow"
                    style={{ width: '320px', height: '120px' }}
                  >
                    <div>
                      <div className="fs-1 fw-bold">{item.number}</div>
                      <div className="fs-5">{item.text}</div>
                    </div>
                  </div>
                </motion.div>
              </Col>
            ))}
          </Row>
        </motion.div>
      </Container>
    </section>
    </>
   
  );
};

export default DonateNow;