import React from 'react';
import { motion } from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/Home.css';
import missionImage1 from '../img/founder-2.jpg';
import missionImage3 from '../img/founder-3.jpg'
import missionImage2 from '../img/Ethiopian kids ♡🇪🇹.jpg';
import { Container, Row, Col, Button, Accordion, Card } from "react-bootstrap";
import { Link } from 'react-router-dom';

const Mission = () => {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5
      }
    }
  };

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.8 }
    }
  };

  return (
    <div className="mission-page" style={{ backgroundColor: 'white', color: 'black' }}>
      {/* Hero Section */}
      <section 
        className="py-5"
        style={{ 
          backgroundColor: '#dc3545',
          color: 'white',
          borderBottom: '10px solid black'
        }}
      >
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={containerVariants}
              >
                <motion.h1 
                  className="display-4 fw-bold mb-4"
                  variants={itemVariants}
                  style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.3)' }}
                >
                  The Daniel Foundation
                </motion.h1>
                <motion.h2 
                  className="display-5 fw-bold mb-4"
                  variants={itemVariants}
                >
                  Humanitarian Assistance in Raya
                </motion.h2>
                <motion.p 
                  className="lead mb-4"
                  variants={itemVariants}
                >
                  Restoring dignity and hope to vulnerable communities affected by conflict and poverty in the Raya region of Ethiopia.
                </motion.p>
                <motion.div variants={itemVariants}>
                  <Link to="/donate-now">
                    <Button 
                      size="lg" 
                      className="px-4 py-3 fw-bold"
                      style={{
                        backgroundColor: 'black',
                        border: 'none',
                        borderRadius: '0',
                        color: 'white',
                        textTransform: 'uppercase'
                      }}
                    >
                      Join Our Mission
                    </Button>
                  </Link>
                </motion.div>
              </motion.div>
            </Col>
            <Col lg={6} className="mt-4 mt-lg-0">
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8 }}
              >
                <img 
                  src={missionImage1} 
                  alt="Children in Raya" 
                  className="img-fluid rounded-0"
                  style={{
                    border: '5px solid white',
                    boxShadow: '0 5px 15px rgba(0,0,0,0.3)'
                  }}
                />
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Mission Statement */}
      <section className="py-5">
        <Container>
          <Row>
            <Col lg={6} className="mb-5 mb-lg-0">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, margin: "-100px" }}
                variants={containerVariants}
              >
                <motion.h2 
                  className="display-5 fw-bold mb-4"
                  style={{ color: '#dc3545' }}
                  variants={itemVariants}
                >
                  Our Mission
                </motion.h2>
                
                <motion.div 
                  className="mb-4"
                  style={{ height: '4px', backgroundColor: 'black', width: '100px' }}
                  variants={itemVariants}
                />
                
                <motion.p 
                  className="mb-4 fs-5"
                  variants={itemVariants}
                >
The Daniel Foundation for Humanitarian Assistance in Raya is a Registered Foundation in Canada. Its mission is to undertake short term emergency and long-term humanitarian and development activities in Raya in order to restore livelihoods, dignity, and help address poverty of vulnerable poor communities. 
                </motion.p>

                <motion.ul 
                  className="mb-4 fs-5"
                  style={{ listStyleType: 'none', paddingLeft: '0' }}
                  variants={itemVariants}
                >
                  <li className="mb-3 d-flex">
                    <span className="me-2" style={{ color: '#dc3545', fontWeight: 'bold' }}>•</span>
                    Provide immediate emergency assistance to save lives and alleviate suffering
                  </li>
                  <li className="mb-3 d-flex">
                    <span className="me-2" style={{ color: '#dc3545', fontWeight: 'bold' }}>•</span>
                    Implement long-term development programs to rebuild communities and restore dignity
                  </li>
                </motion.ul>

                <motion.div 
                  className="p-4 mb-4"
                  style={{ 
                    backgroundColor: '#f8f9fa', 
                    borderLeft: '4px solid #dc3545',
                    borderRight: '1px solid #ddd',
                    borderTop: '1px solid #ddd',
                    borderBottom: '1px solid #ddd'
                  }}
                  variants={itemVariants}
                >
                  <p className="mb-0 fw-bold">
                  The focus and target communities of the Raya Project are poor girls, poor boys, orphan children and most vulnerable women who do not have any income means to sustain themselves.                  </p>
                </motion.div>
              </motion.div>
            </Col>
            
            <Col lg={6}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              >
                <div className="position-relative">
                  <img 
                    src={missionImage3} 
                    alt="Mission in action" 
                    className="img-fluid rounded-0 mb-3"
                    style={{ border: '5px solid white', boxShadow: '0 5px 15px rgba(0,0,0,0.3)' }}
                  />
                  <div 
                    className="p-3 position-absolute"
                    style={{
                      bottom: "-20px",
                      right: "-20px",
                      maxWidth: "250px",
                      zIndex: 10,
                      backgroundColor: 'black',
                      color: 'white'
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="bg-white p-2 me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#dc3545" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                        </svg>
                      </div>
                      <div>
                        <div className="fw-bold">Our Impact</div>
                        <small>Changing lives daily</small>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Emergency Phase Section */}
      <section className="py-5" style={{  color: 'black' }}>
        <Container>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <Row className="align-items-center">
              <Col lg={6} className="mb-4 mb-lg-0">
              <motion.h2 
                  className="display-5 fw-bold mb-4"
                  style={{ color: '#dc3545' }}
                  variants={itemVariants}
                >
                  Overall Objective
                </motion.h2>
                <h4 className=" fw-bold mb-4" style={{ color: '#dc3545' }}>
                  Emergency Phase Assistance
                </h4>
                
                <div className="mb-4" style={{ height: '4px',  width: '100px' }} />
                
                <p className="fs-5 mb-4">
                  During the immediate aftermath of conflict, our emergency response focuses on life-saving interventions for the most vulnerable populations in Raya and South Wollo:
                </p>
                
                <ul className="fs-5 mb-4" style={{ listStyleType: 'none', paddingLeft: '0' }}>
                  <li className="mb-3 d-flex">
                    <span className="me-2" style={{ color: '#dc3545', fontWeight: 'bold' }}>•</span>
                    <strong>Nutrition Support:</strong> Distribution of high-nutrient food packages to malnourished children and nursing mothers
                  </li>
                  <li className="mb-3 d-flex">
                    <span className="me-2" style={{ color: '#dc3545', fontWeight: 'bold' }}>•</span>
                    <strong>Medical Aid:</strong> Mobile health clinics providing essential medicines and maternal care
                  </li>
                  <li className="mb-3 d-flex">
                    <span className="me-2" style={{ color: '#dc3545', fontWeight: 'bold' }}>•</span>
                    <strong>Shelter:</strong> Emergency housing solutions for displaced families
                  </li>
                  <li className="mb-3 d-flex">
                    <span className="me-2" style={{ color: '#dc3545', fontWeight: 'bold' }}>•</span>
                    <strong>Trauma Support:</strong> Counseling services for survivors of gender-based violence
                  </li>
                </ul>
                
                <p className="fs-5">
                  Our emergency teams work closely with local community leaders to identify those in greatest need, ensuring aid reaches the most vulnerable first.
                </p>
              </Col>
              
              <Col lg={6}>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0" className="mb-3 rounded-0 border-0">
                    <Accordion.Header className="bg-dark text-white">
                      <h3 className="mb-0 fs-5 fw-bold">Emergency Food Distribution</h3>
                    </Accordion.Header>
                    <Accordion.Body className="bg-dark text-white">
                      <p>
                        We provide monthly food packages containing:
                      </p>
                      <ul>
                        <li>Fortified flour with essential vitamins</li>
                        <li>High-protein legumes</li>
                        <li>Therapeutic foods for malnourished children</li>
                        <li>Cooking oil and salt</li>
                      </ul>
                      <p>
                        Each package is designed to feed a family of five for one month and includes nutrition education materials.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  
                  <Accordion.Item eventKey="1" className="mb-3 rounded-0 border-0">
                    <Accordion.Header className="bg-dark text-white">
                      <h3 className="mb-0 fs-5 fw-bold">Medical Interventions</h3>
                    </Accordion.Header>
                    <Accordion.Body className="bg-dark text-white">
                      <p>
                        Our mobile clinics provide:
                      </p>
                      <ul>
                        <li>Basic healthcare services</li>
                        <li>Prenatal and postnatal care</li>
                        <li>Vaccinations for children</li>
                        <li>Treatment for waterborne diseases</li>
                        <li>Mental health first aid</li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  
                  <Accordion.Item eventKey="2" className="rounded-0 border-0">
                    <Accordion.Header className="bg-dark text-white">
                      <h3 className="mb-0 fs-5 fw-bold">Shelter Program</h3>
                    </Accordion.Header>
                    <Accordion.Body className="bg-dark text-white">
                      <p>
                        For families who lost their homes, we provide:
                      </p>
                      <ul>
                        <li>Emergency tent shelters</li>
                        <li>Basic household items (blankets, cooking utensils)</li>
                        <li>Hygiene kits</li>
                        <li>Materials for temporary structures</li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </motion.div>
        </Container>
      </section>

      {/* Recovery and Development Section */}
      <section className="py-5">
        <Container>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="display-5 fw-bold mb-5 text-center" style={{ color: '#dc3545' }}>
              Recovery and Development Programs
            </h2>
            
            <p className="text-center fs-5 mb-5 mx-auto" style={{ maxWidth: '800px' }}>
              As communities transition from emergency to recovery, we implement sustainable development programs to rebuild livelihoods and restore hope:
            </p>
            
            <Row className="g-4">
              <Col md={4}>
                <Card className="h-100 rounded-0 border-0 shadow-sm">
                  <Card.Body className="p-4">
                    <div 
                      className="icon-box mb-4 rounded-0"
                      style={{ 
                        backgroundColor: '#dc3545',
                        width: '60px',
                        height: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" viewBox="0 0 16 16">
                        <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                      </svg>
                    </div>
                    <Card.Title className="fw-bold mb-3" style={{ color: '#dc3545' }}>Education Restoration</Card.Title>
                    <Card.Text>
                      <ul className="ps-3" style={{ listStyleType: 'none' }}>
                        <li className="mb-2">• Rebuilding 5 destroyed schools</li>
                        <li className="mb-2">• School feeding program for 2,000 children</li>
                        <li className="mb-2">• Teacher training programs</li>
                        <li className="mb-2">• Scholarship fund for orphaned students</li>
                        <li>• Psychosocial support for trauma recovery</li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              
              <Col md={4}>
                <Card className="h-100 rounded-0 border-0 shadow-sm">
                  <Card.Body className="p-4">
                    <div 
                      className="icon-box mb-4 rounded-0"
                      style={{ 
                        backgroundColor: '#dc3545',
                        width: '60px',
                        height: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" viewBox="0 0 16 16">
                        <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
                      </svg>
                    </div>
                    <Card.Title className="fw-bold mb-3" style={{ color: '#dc3545' }}>Livelihood Recovery</Card.Title>
                    <Card.Text>
                      <ul className="ps-3" style={{ listStyleType: 'none' }}>
                        <li className="mb-2">• Seed funding for small businesses</li>
                        <li className="mb-2">• Vocational training programs</li>
                        <li className="mb-2">• Agricultural support (seeds, tools)</li>
                        <li className="mb-2">• Microfinance initiatives for women</li>
                        <li>• Animal restocking programs</li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              
              <Col md={4}>
                <Card className="h-100 rounded-0 border-0 shadow-sm">
                  <Card.Body className="p-4">
                    <div 
                      className="icon-box mb-4 rounded-0"
                      style={{ 
                        backgroundColor: '#dc3545',
                        width: '60px',
                        height: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" viewBox="0 0 16 16">
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                      </svg>
                    </div>
                    <Card.Title className="fw-bold mb-3" style={{ color: '#dc3545' }}>Community Infrastructure</Card.Title>
                    <Card.Text>
                      <ul className="ps-3" style={{ listStyleType: 'none' }}>
                        <li className="mb-2">• Clean water projects (wells, purification)</li>
                        <li className="mb-2">• Health clinic rehabilitation</li>
                        <li className="mb-2">• Sanitation facilities</li>
                        <li className="mb-2">• Community centers for social services</li>
                        <li>• Road repair for market access</li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </motion.div>
        </Container>
      </section>

      {/* Call to Action */}
      <section className="py-5" style={{ backgroundColor: '#dc3545', color: 'white' }}>
        <Container>
          <Row className="align-items-center">
            <Col lg={8} className="mb-4 mb-lg-0">
              <h2 className="display-5 fw-bold mb-3">Your Support Makes a Difference</h2>
              <p className="fs-5 mb-4">
                With your help, we can continue providing life-saving assistance and rebuilding communities in Raya. 
                Every contribution directly impacts those recovering from conflict and poverty.
              </p>
              <div className="d-flex flex-wrap gap-3">
                <Link to="/donate-now">
                  <Button 
                    size="lg" 
                    className="px-4 py-3 fw-bold rounded-0"
                    style={{
                      backgroundColor: 'black',
                      border: 'none',
                      color: 'white'
                    }}
                  >
                    Donate Now
                  </Button>
                </Link>
                <Link to="/get-involved">
                  <Button 
                    size="lg" 
                    variant="outline-light" 
                    className="px-4 py-3 fw-bold rounded-0"
                  >
                    Volunteer
                  </Button>
                </Link>
              </div>
            </Col>
            <Col lg={4}>
              <div className="p-4" >
                <h3 className="fw-bold mb-3">Other Ways to Help</h3>
                <ul className="ps-3" style={{ listStyleType: 'none' }}>
                  <li className="mb-2">• Corporate partnerships</li>
                  <li className="mb-2">• Fundraising campaigns</li>
                  <li className="mb-2">• In-kind donations</li>
                  <li>• Awareness raising</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Mission;