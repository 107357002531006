import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope, faHeart } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons';
import logo from '../img/danel-logo_1x.png';
import '../style/Footer.css';

const Footer = () => {
  // Framer Motion animations
  const bounceAnimation = {
    y: [0, -10, 0],
    transition: { duration: 0.5, repeat: Infinity, repeatType: "reverse" }
  };

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  return (
    <footer className="modern-footer pt-5 bg-black text-white">
      <Container>
        <Row className="g-4 mb-5">
          {/* Organization Info */}
          <Col lg={4} md={6}>
            <motion.div initial="hidden" whileInView="visible" variants={fadeIn}>
              <Link to="/" className="d-flex align-items-center mb-4 text-decoration-none">
                <img src={logo} alt="The Daniel Foundation" height="60" className="me-3" />
                <div>
                  <h3 className="mb-0 text-white" style={{ fontFamily: "'Bentham', serif" }}>
                    The Daniel Foundation
                  </h3>
                  <p className="text-white mb-0" style={{ fontFamily: "'Montserrat', sans-serif" }}>
                    for Humanitarian Assistance in Raya
                  </p>
                </div>
              </Link>
              <p className="text-white mb-4" style={{ fontFamily: "'Montserrat', sans-serif" }}>
                Empowering communities through humanitarian assistance and sustainable development.
              </p>
              <ul className="contact-info list-unstyled mb-4">
                <li className="mb-2">
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2 text-danger" />
                  <span>Unite 65 -3120 Sheppard Ave East

North York, Ontario
CANADA</span>
                </li>
                <li className="mb-2">
                  <FontAwesomeIcon icon={faPhone} className="me-2 text-danger" />
                  <span>+416-666-7754
                  +1-855-833-4247</span>
                </li>
                <li className="mb-2">
                  <FontAwesomeIcon icon={faEnvelope} className="me-2 text-danger" />
                  <span>contact@danielfoundation.org</span>
                </li>
              </ul>
            </motion.div>
          </Col>

          {/* Quick Links */}
          <Col lg={4} md={6}>
            <motion.div initial="hidden" whileInView="visible" variants={fadeIn}>
              <h3 className="footer-title mb-4" style={{ fontFamily: "'Bentham', serif" }}>Quick Links</h3>
              <Row>
                <Col sm={6}>
                  <ul className="quick-links list-unstyled">
                    <li className="mb-2"><Link to="/" className="text-decoration-none text-white">Home</Link></li>
                    <li className="mb-2"><Link to="/about-us" className="text-decoration-none text-white">About Us</Link></li>
                    <li className="mb-2"><Link to="/who-we-are" className="text-decoration-none text-white">Who We Are</Link></li>
                    <li className="mb-2"><Link to="/how-we-do-it" className="text-decoration-none text-white">How We Do It</Link></li>
                  </ul>
                </Col>
                <Col sm={6}>
                  <ul className="quick-links list-unstyled">
                    <li className="mb-2"><Link to="/contact-us" className="text-decoration-none text-white">Contact Us</Link></li>
                    <li className="mb-2"><Link to="/donate-now" className="text-decoration-none text-white">Donate Now</Link></li>
                    <li className="mb-2"><Link to="#" className="text-decoration-none text-white">Privacy Policy</Link></li>
                    <li className="mb-2"><Link to="#" className="text-decoration-none text-white">Terms of Service</Link></li>
                  </ul>
                </Col>
              </Row>
            </motion.div>
          </Col>

          {/* Newsletter & Donation */}
          <Col lg={4} md={12}>
            <motion.div initial="hidden" whileInView="visible" variants={fadeIn}>
              <h3 className="footer-title mb-4" style={{ fontFamily: "'Bentham', serif" }}>Stay Connected</h3>
              <p className="text-white mb-4" style={{ fontFamily: "'Montserrat', sans-serif" }}>
                Subscribe to our newsletter for updates on our humanitarian work.
              </p>
              <form className="mb-4">
                <div className="mb-3">
                  <input 
                    type="email" 
                    className="form-control newsletter-input bg-dark text-white border-secondary" 
                    placeholder="Your email address" 
                  />
                </div>
                <button type="submit" className="btn btn-danger w-100 mb-3">
                  Subscribe Now
                </button>
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                  <Link to="/donate-now" className="btn btn-outline-danger w-100">
                    <motion.span animate={bounceAnimation}>
                      Donate Now
                    </motion.span>
                  </Link>
                </motion.div>
              </form>
              <div className="social-links mt-4">
                <Link to="#" className="social-icon me-3 text-white"><FontAwesomeIcon icon={faFacebookF} /></Link>
                <Link to="#" className="social-icon me-3 text-white"><FontAwesomeIcon icon={faTwitter} /></Link>
                <Link to="#" className="social-icon me-3 text-white"><FontAwesomeIcon icon={faInstagram} /></Link>
                <Link to="#" className="social-icon me-3 text-white"><FontAwesomeIcon icon={faLinkedinIn} /></Link>
                <Link to="#" className="social-icon text-white"><FontAwesomeIcon icon={faYoutube} /></Link>
              </div>
            </motion.div>
          </Col>
        </Row>
      </Container>

      {/* Footer Bottom */}
      <div className="footer-bottom py-4 border-top border-secondary">
        <Container>
          <Row>
            <Col md={12} className="text-center">
              <p className="mb-0" style={{ fontFamily: "'Montserrat', sans-serif" }}>
                Copyright 2025 - 2028 | All Rights Reserved to The Daniel Foundation | 
                Made with <FontAwesomeIcon icon={faHeart} className="text-danger" /> for Humanity
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;